<template>
  <modal
    name="addgarage-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header bg-slate-800 w-full xl:rounded-t text-white"
    >
      <h4 class="inline-block p-4 w-full font-bold">GARAJ EKLE</h4>
      <button class="p-4 w-1/12" @click="$modal.hide('addgarage-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form @submit.prevent="save()" class="p-5">
      <div class="grid grid-col-1 gap-y-3">
        <customInput
          v-model="name"
          type="text"
          min="3"
          max="60"
          :required="true"
          title="Dolum Tesis İsmi"
        />
        <div class="flex justify-between items-center gap-x-2">
          <customInput
            v-model="fullname"
            type="text"
            min="3"
            max="40"
            :required="false"
            title="Dolum Tesisi İletişim: Ad Soyad"
          />
          <customInput
            v-model="phone"
            type="number"
            :required="false"
            title="Dolum Tesisi İletişim: Telefon"
          />
        </div>
        <customInput
          v-model="adress"
          rows="5"
          type="textarea"
          min="5"
          max="5000"
          :required="true"
          title="Adres"
        />
        <div class="flex justify-between items-center gap-x-2">
          <customInput
            v-model="latitude"
            type="text"
            min="5"
            max="40"
            :required="true"
            title="Enlem"
          />
          <customInput
            v-model="longitude"
            type="text"
            min="5"
            max="40"
            :required="true"
            title="Boylam"
          />
        </div>
        <customInput
          v-model="description"
          type="textarea"
          :rows="5"
          :required="true"
          title="Açıkalama"
        />

        <buttonItem :load="load" buttonClass="w-full bg-slate-800" />
      </div>
    </form>
  </modal>
</template>
<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import axios from "axios";
export default {
  name: "garagemodule-modal",
  components: {
    customInput,
    buttonItem,
  },
  data() {
    return {
      load: false,
      name: "",
      fullname: "",
      phone: "",
      description: "",
      latitude: "",
      longitude: "",
      adress: "",
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post(
          "http://35.222.216.48:3000/garages/add",
          {
            name: this.name,
            fullname: this.fullname,
            phone: this.phone,
            description: this.description,
            latitude: this.latitude,
            longitude: this.longitude,
            adress: this.adress,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.user.token,
            },
          }
        )
        .then(() => {
          this.load = false;
          this.$swal({
            icon: "success",
            title: "BAŞARILI !",
            text: "BAŞARI İLE EKLENDİ !",
          }).then(() => {
            (this.name = ""),
              (this.fullname = ""),
              (this.phone = ""),
              (this.description = ""),
              (this.latitude = ""),
              (this.longitude = ""),
              (this.adress = ""),
              this.$modal.hide("addgarage-modal");
            this.$emit("refresh", true);
          });
        })
        .catch((err) => {
          this.load = false;
          console.log(err);
          alert(err.response.data.message);
        });
    },
  },
};
</script>
