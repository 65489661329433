<template>
  <div class="h-[91%] relative">
    <PlanModal ref="planModal" />

    <l-map class="h-full" :zoom="zoom" :center="center" ref="mapRef">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker
        v-for="item in List"
        :key="item.vehicle_id"
        @click="zoomToMarker(item)"
        :icon="icon"
        :title="item.plaque"
        :lat-lng="[item.latitude, item.longitude]"
      >
        <l-tooltip :options="{}">
          {{ item.addDate | getDate }}
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import PlanModal from "./components/addPlannign.vue";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { icon } from "leaflet";
import axios from "axios";

import moment from "moment";
import "moment/locale/tr.js";
export default {
  name: "mapsPage",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    PlanModal,
  },
  data() {
    return {
      url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      attribution:
        '&copy; <a target="_blank" href="http://siriustedarik.com">siriustedarik.com</a> contributors',
      zoom: 10,
      center: [41.05970789164794, 28.939333779846454],
      markerLatLng: [41.05970789164794, 28.939333779846454],
      icon: icon({
        iconUrl: require("@/assets/navigationicon.png"),
        iconSize: [40, 45], //40, 45
        iconAnchor: [16, 37],
      }),

      List: [],
      load: false,
      intervalId: 0,
    };
  },
  methods: {
    async getAll() {
      this.load = true;
      axios
        .get("http://35.222.216.48:3000/tracking", {
          headers: {
            Authorization: "Bareer " + this.$store.state.user.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
          this.$store.commit("setList", this.List);
          // if (isCreated) this.zoomToMarker(this.List[0], 13);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            alert("Oturum süreniz doldu,giriş sayfasına yönlendiriliyorsunuz");
            this.$store.commit("signOut");
            this.$router.push("/");
          }
          console.log("İşlem başarısız oldu hata kodu :", err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    zoomToMarker(item, zoom = 17) {
      const mapRef = this.$refs.mapRef;
      mapRef.mapObject.setView([item.lat, item.lng], zoom); // 15 burada istediğiniz zoom seviyesini temsil eder
    },
  },
  created() {
    this.getAll(true);
    if (!(this.$store.state.isAuth && this.$store.state.user.token)) {
      clearInterval(this.intervalId);
      this.$router.push("/");
    }

    this.intervalId = setInterval(() => {
      this.getAll();
    }, 2000);
  },
  watch: {
    "$store.state.activeVehicle"(val) {
      const find = this.List.find((item) => item.deviceId == val.deviceId);
      if (typeof find != "undefined") {
        this.zoomToMarker(find);
      }
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  filters: {
    getDate(val) {
      return moment(val).calendar();
    },
  },
};
</script>
