<template>
  <div class="w-full ">
      <label v-if="title" class="textcol font-semibold flex gap-1 items-center ">{{ title }} <p class="text-[10px] " v-if="required"><i class="fa-sharp fa-solid fa-star-of-life text-[7px]"></i></p></label>
      <input v-if="type !== 'textarea' && type !== 'select'"
          class="w-full border bordercol rounded py-3 outline-none px-3 text-xs" :type="type" :disabled="disabled"
          :required="required" :readonly="readonly" v-model="selected" :placeholder="placeholder" :min="min" :max="max"
          :minlength="min" :maxlength="max" :class="inputClass" @change="(r) => type == 'file' && $emit('change', r)" />

      <select v-else-if="type !== 'textarea' && type == 'select'"
          class="w-full border bordercol rounded py-3 outline-none pl-3 text-xs" name="" id="" v-model="selected"
          :required="required">

          <option v-for="(item, index) in selectList" :key="index" :value="item.value">
              {{ item.name }}
          </option>
      </select>

      <textarea v-else class="w-full border bordercol rounded py-3 outline-none px-3 text-xs" :class="textareaClass"
          :type="type" :required="required" :readonly="readonly" v-model="selected" :rows="rows || 1">
    </textarea>

  </div>
</template>
<script>
export default {
  name: "multipleInputs",
  props: [
      "title",
      "type",
      "value",
      "required",
      "disabled",
      "min",
      "max",
      "readonly",
      "textareaClass",
      "selectList",
      "placeholder",
      "inputClass",
      "rows"
  ],
  data() {
      return {
          selected: this.value,
          load: false,
      };
  },
  model: {
      event: "change",
      prop: "value",
  },
  created() {
      if (this.type != "file") {
          this.selected = this.value;
      } else if (this.type == "file" && this.videoId) this.getUrl();
  },
  watch: {
      value(newValue) {
          this.selected = newValue;
      },
      selected(val) {
          this.$emit("change", val);
      },
  },
};
</script>
