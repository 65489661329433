<template>
  <div id="app" class="h-screen">
    <NavbarItem v-if="$store.state.user.token && $store.state.isAuth"/>
    <!-- <SideBarItem v-if="$store.state.user.token && $store.state.isAuth" /> -->
    <router-view/>
  </div>
</template>

<script>
import NavbarItem from './partitions/navbar.vue'
// import SideBarItem from './partitions/sidebar.vue';
export default {
  name: 'AppView',
  components:{
    NavbarItem,
    // SideBarItem
  },
}
</script>

