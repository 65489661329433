<template>
    <div>
        <h5 class="text-sm">{{ title }}</h5>
        <gmap-autocomplete
          class="w-full  dark:text-black rounded-md border-black border-opacity-25 text-black border h-10 px-4 bg-white"
          @place_changed="setPlace"
          :value="adress"
          v-if="!hideAutoComplate"
        >
        </gmap-autocomplete>
    </div>
  </template>
  
  <script>
  export default {
    name: "google-maps-input",
    props: {
      hideMap: {
        type: Boolean,
        default: false,
        required: false,
      },
      hideAutoComplate: {
        type: Boolean,
        default: false,
        required: false,
      },
      placeHolder:{
        type:String,
        default:''
      },
      height: {
        type: String,
        default: "400px",
        required: false,
      },
      lat: {
        type: Number,
        default: 0,
        required: false,
      },
      lng: {
        type: Number,
        default: 0,
        required: false,
      },
      defaultAdress: {
        type: String,
        default: "",
        required: false,
      },
      lightDark: {
        type: Boolean,
        default: false,
        required: false,
      },
      draggable: {
        type: Boolean,
        default: false,
        required: false,
      },
  
      title: {
        type: String,
        default: "Adres",
        required: false,
      },
      load: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        adress: "",
        position: { lat: 41.015137, lng: 28.97953 },
        center: { lat: 41.015137, lng: 28.97953 },
      };
    },
    methods: {
      setPlace(val, dragged = false) {
        const adress = dragged ? this.defaultAdress : val.formatted_address;
  
        const lat = parseFloat(dragged ? val.latLng.lat() : val.geometry.location.lat());
        const lng = parseFloat(dragged ? val.latLng.lng() : val.geometry.location.lng());
  
        this.$emit("change", {
          adress: adress,
          lat: lat,
          lng: lng,
        });
  
        this.adress = adress;
        this.position = {
          lat: lat,
          lng: lng,
        };
  
        this.center = {
          lat: lat,
          lng: lng,
        };
      },
    },
    created() {
      this.position = { lat: Number(this.lat), lng: Number(this.lng) };
      this.adress = this.defaultAdress;
    },
    watch: {
      defaultAdress(val) {
        this.adress = val;
      },
      lat(val) {
        this.position.lat = Number(val);
      },
      lng(val) {
        console.log(val);
        this.position.lng = Number(val);
      },
    },
  };
  </script>
  
  <style></style>