import Vue from 'vue'
import VueRouter from 'vue-router'

// pages
import AuthPage from '../views/authPage'
import MapsPage from '../views/mapsPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AuthPage',
    component: AuthPage
  },
  {
    path: '/maps',
    name: 'MapsPage',
    component: MapsPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
