<template>
  <tr class="text-gray-200 text-center">
    <td v-if="load" :colspan="colspan" class="p-4">
      <i class="fas fa-spinner fa-spin"></i>
         Yükleniyor...
    </td>
    <td v-if="!load && length == 0" :colspan="colspan" class="p-4">
      Listelenebilecek herhangi bir veri bulunamadı 
    </td>
  </tr>
</template>

<script>
export default {
  name: "table-loader",
  props: ["load", "length", "colspan"],
};
</script>
