<template>
  <div
    class="bg-gray-50 bg-cover bg-center h-full"
    style="
      background-image: url('https://images.unsplash.com/photo-1485575301924-6891ef935dcd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80');
    "
  >
    <div
      class="bg-black h-full flex items-center xl:justify-end justify-center bg-opacity-50 px-5 xl:px-20"
    >
      <div
        class="bg-white bg-opacity-95 shadow-xl p-5 xl:py-20 xl:w-4/12 xl:rounded-xl rounded h-[50vh]"
      >
        <div class="border-b border-black border-opacity-10 p-2 text-2xl">
          YÜKÜM GÜVENDE
          <p class="text-xs mt-3 font-light">
            Araçlarınızı güvenlir şekilde takip edin...
          </p>
        </div>

        <form class="p-2" @submit.prevent="save">
          <input
            type="text"
            v-model="username"
            required
            maxlength="50"
            minlength="1"
            placeholder="Kullanıcı Adı"
            class="border-b-2 bg-transparent mt-5 w-full p-2 outline-none focus:border-blue-400 transition-all duration-500"
          />
          <input
            type="password"
            v-model="password"
            required
            maxlength="25"
            minlength="1"
            placeholder="Parola"
            class="border-b-2 bg-transparent mt-5 w-full p-2 outline-none focus:border-blue-400 transition-all duration-500"
          />

          <button
            :disabled="load"
            class="w-full rounded-xl shadow-sm mt-5 bg-gray-200 hover:bg-blue-500 transition-all duration-300 hover:text-white py-3"
          >
            <span v-if="!load"> Giriş Yap </span>

            <span v-else>
              <i class="fas fa-spinner fa-spin"></i>
              Giriş Yapılıyor
            </span>
          </button>
        </form>
      </div>
    </div>

    <p
      class="fixed bottom-4 left-5 right-5 text-center text-xs text-white text-opacity-70"
    >
      Tüm hakları saklıdır Copyright © Sirius tedarik danışmanlık ve dış ticaret a.ş
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      username: "",
      password: "",
      load: false,
    };
  },
  methods: {
    save() {
      this.load = true;
      axios
        .post("http://35.222.216.48:3000/users/sign/in", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          this.load = false;
          this.$store.commit("signIn", res.data.data);
          this.$router.push("/maps");
        })
        .catch((err) => {
          this.load = false;
          console.log(err);
          alert(err.response.data.message);
        });
    },
  },
  created() {
    if (this.$store.state.isAuth && this.$store.state.user.token) {
      this.$router.push("/maps");
    }
  },
};
</script>
