<template>
  <div
    class="bg-white border-t h-full xl:h-[20vh] p-5 z-[999999] w-full xl:w-[77%] border-r absolute items-center justify-between left-0 bottom-0"
    v-if="modal"
  >
    <div class="flex items-center justify-between">
      <h4 class="font-bold">ROTA OLUŞTUR</h4>

      <button @click="hideModal()">
        <i class="fas fa-times fa-2x opacity-25"></i>
      </button>
    </div>
    <div class="w-full max-h-full overflow-y-auto mt-5">
      <div class="xl:flex items-center justify-between w-full">
        <div class="w-full xl:w-2/12">
          <GeoMapVue title="Başlangıç" />
        </div>

        <div class="w-full xl:w-2/12 xl:pl-2 mt-2 xl:mt-0">
          <GeoMapVue title="Bitiş" />
        </div>
        <div class="w-full xl:w-2/12 xl:pl-2 mt-2 xl:mt-0">
          <label>Araç</label>
          <select
            class="w-full border border-black border-opacity-25 rounded-md h-10"
            required
          >
            <option value="">Seçilmedi</option>
            <option value="34VB2692">34VB2692</option>
          </select>
        </div>
        <div class="w-full xl:w-3/12 xl:pl-2 mt-2 xl:mt-0">
          <label>Sürücü</label>
          <select
            class="w-full border border-black border-opacity-25 rounded-md h-10"
            required
          >
            <option value="">Seçilmedi</option>
            <option value="ahmetdeniz">Ahmet Deniz</option>
          </select>
        </div>
        <div class="w-full xl:w-3/12 xl:pl-2 mt-6">
          <button
            @click="save()"
            class="w-full bg-blue-500 hover:bg-blue-600 text-white rounded h-10"
          >
            Oluştur
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GeoMapVue from "./geoMap.vue";
export default {
  name: "SideBarItem",
  components: {
    GeoMapVue,
  },
  data() {
    return {
      List: [],
      load: false,
      modal: false,
    };
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    getAll() {
      this.load = true;
      axios
        .get("http://35.222.216.48/vehicles/get/all", {
          headers: {
            Authorization: "Bareer " + this.$store.state.user.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
        });
    },
    save() {
      alert("Plan başarı ile eklendi");
      this.hideModal();
    },
    selectedItem(val) {
      this.$store.commit("setActiveVehicle", val);
    },
  },
  created() {
    this.getAll();
  },
};
</script>
