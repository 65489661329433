import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{},
    dataList:[],
    isAuth:false,
    activeVehicle:null,
    sidebar:false
  },
  getters: {
  },
  mutations: {
    setSidebar(state,val){
      state.sidebar=val;
    },
    setActiveVehicle(state,val){
        state.activeVehicle=val;
    },
    setList(state,val){
      state.dataList=val;
    },
    signIn(state,val){
      state.isAuth=true;
      state.user=val;
    },
    signOut(state){
      state.isAuth=false;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
