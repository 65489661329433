<template>
  <div
    class="bg-white h-[9vh] xl:h-[10vh] p-5 z-[99999] w-full shadow-xl flex items-center justify-between"
  >
    <div class="flex w-8/12">
      <div>
        <h4 class="font-bold">YÜKÜM GÜVENDE</h4>
        <h4 class="font-light text-xs">Herşey kontrolünüz altında</h4>
      </div>
      <div>
        <button
          class="xl:hidden"
          @click="$store.commit('setSidebar', !$store.state.sidebar)"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
    </div>

    <div class="flex w-4/12 justify-end items-center gap-10">
      <garagemodule ref="garagemodule" />
      <fillingmodule ref="fillingmodule" />
      <button @click="garagemodule()" class="">Garajlar</button>
      <button @click="fillingmodule()" class="">Dolum Tesisleri</button>

      <button class="xl:block hidden" @click="signOut()">Çıkış Yap</button>
    </div>
  </div>
</template>

<script>
import garagemodule from "@/views/garagemodule/index.vue";
import fillingmodule from "@/views/fillingplantmodule/index.vue";
export default {
  name: "NavbarItem",
  components: {
    garagemodule,
    fillingmodule,
  },
  data() {
    return {
      authority: "",
    };
  },
  methods: {
    garagemodule() {
      this.$modal.show("garagemodule-modal");
    },
    fillingmodule() {
      this.$modal.show("fillingmodule-modal");
    },
    signOut() {
      if (confirm("Çıkış yapmak istediğinize emin misiniz?")) {
        this.$store.commit("signOut");
        this.$router.push("/");
      }
    },
  },
};
</script>
