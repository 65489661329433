<template>
  <modal
    name="fillingmodule-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '60%'"
    :scrollable="true"
  >
    <addTable @refresh="getAll()" />
    <editTable ref="editTable" :Detail="selected" @refresh="getAll()" />
    <div class="items-center">
      <div class="pt-3 pb-2 pl-4 border-gray-100">
        <div class="flex">
          <h4 class="inline-block w-11/12 font-bold">DOLUM TESİS TABLOSU</h4>
          <button class="p-2 w-1/12" @click="$modal.hide('fillingmodule-modal')">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <button
          @click="addFacility()"
          class="bg-red-800 px-4 py-3 mt-2 rounded-lg font-bold text-white"
        >
          TESİS EKLE
        </button>
      </div>
      <div class="w-full px-4">
        <div
          class="shadow table-height-value max-md:overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead
              class="bg-white border-black border-dotted border-b-2 border-opacity-20"
            >
              <tr>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20 px-2"
                  style="min-width: 100px !important"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20 px-2"
                  style="min-width: 150px !important"
                >
                  Tesis adı
                </th>
                <th
                  scope="col"
                  class="table-th text-left border-r border-opacity-20 px-2"
                  style="min-width: 200px !important"
                >
                  Adres
                </th>
                <th
                  scope="col"
                  class="table-th border-r border-opacity-20 px-2 text-center"
                  style="min-width: 200px !important"
                >
                  İşlem
                </th>
              </tr>
            </thead>
            <tbody class="divide-y-2 divide-gray-500 bg-slate-800 opacity-90">
              <tr v-for="(item, index) in List" :key="index" class="text-white">
                <td class="table-td border-r border-opacity-20 px-2">
                  <span> {{ index + 1 }} </span>
                </td>
                <td class="table-td border-r border-opacity-20 px-2">
                  <p>{{ item.name }}</p>
                </td>
                <td class="table-td border-r border-opacity-20 px-2">
                  <p>
                    {{ item.adress }}
                  </p>
                </td>
                <td class="table-td border-r border-opacity-20 px-2">
                  <div class="flex gap-8 justify-center">
                    <button @click="editFacility(item)">
                      <i class="fas fa-pen"></i>
                    </button>
                    <button @click="deleteItem(item)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
              <tableLoader :load="load" :length="List.length" colspan="4" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
//global components
import tableLoader from "@/components/general/table-loader.vue";

//components
import editTable from "./components/editfacility.vue";
import addTable from "../fillingplantmodule/components/addfacility.vue";

import axios from "axios";

export default {
  components: {
    tableLoader,
    addTable,
    editTable,
  },
  name: "index-table",

  data() {
    return {
      load: false,
      List: [],
      selected: "",
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get("http://35.222.216.48:3000/outpoint/get/all", {
          headers: {
            Authorization: "Bareer " + this.$store.state.user.token,
          },
        })
        .then((res) => {
          this.load = false;
          this.List = res.data.data;
        })
        .catch((err) => {
          this.load = false;
          alert(err);
        });
    },

    addFacility() {
      this.$modal.show("addfacility-modal");
    },
    editFacility(val) {
      this.selected = val;
      this.$refs.editTable.show();
    },
    deleteItem(item) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Bunu geri alamazsınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "Hayır, vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              "http://192.168.1.25:3000/outpoint/delete",
              {
                rowId: item.id,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.user.token,
                },
              }
            )
            .then((res) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "BAŞARILI",
                text: res.data.message,
                showConfirmButton: true,
                confirmButtonText: "TAMAM",
                timer: 1500,
              });
              this.getAll();
            })
            .catch((err) => {
              console.error(err);
              const errorMessage = err.response ? err.response.data.message : err.message;
              this.$swal({
                position: "center",
                icon: "error",
                title: "HATA",
                text: errorMessage,
                showConfirmButton: true,
                confirmButtonText: "Tamam",
                timer: 1500,
              });
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
